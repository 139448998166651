"use client";

import { useEffect } from "react";
import HyperDX from "@hyperdx/browser";

export function HyperDXInit() {
  useEffect(() => {
    HyperDX.init({
      apiKey: process.env.NEXT_PUBLIC_HYPERDX_API_KEY!,
      service: "my-frontend-app",
      consoleCapture: true,
      advancedNetworkCapture: true,
    });
  }, []);

  return null;
}
